


































import { Component, Vue, Watch } from 'vue-property-decorator';
import { DEFAULT_COUNT_PER_PAGE, DEFAULT_PAGE_NUMBER } from '@/utils/constants';
import Dropdown from '@/components/swine/Dropdown.vue';
import DateDropdown from '../dropdowns/DateDropdown.vue';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { getFirstDayOfMonth, getLastDayOfMonth } from '@/utils/moment';
import { UserSessionStore } from '@/store/swine/user-session';
import { Country } from '@/models/swine/country/country.model';
import { CountryStores } from '@/store/swine/country';
import { generateUniqueId } from '@/utils/lodash';
import { ProfileStores } from '@/store/swine/profile';
import { Role } from '@/utils/constants';

@Component({
  components: {
    Dropdown,
    DateDropdown,
  },
})
export default class DashboardFilter extends Vue {
  countries: DropdownOption[] = [];
  currentRowsPerPage = DEFAULT_COUNT_PER_PAGE;
  currentPageNumber = DEFAULT_PAGE_NUMBER;
  offsetValue = 0;
  currentFilter = '';
  sortBy = 'createdDate:asc';
  selectedCountry = '';
  selectedStartDate = '';
  selectedEndDate = '';
  startDate: Date | null = null;
  endDate: Date | null = null;
  dropdownPosition: string | null = 'is-bottom-left';
  userSessionStore = UserSessionStore.detail;
  currentCountry: Country | null = null;
  countryIndex = -1;
  countryStore = CountryStores.list;
  userProfileStore = ProfileStores.profile;

  @Watch(`countryStore.response`)
  retrieveCountries() {
    if (this.countryStore.response && this.userProfileStore.userProfile) {
      this.countries = [];
      this.countries.push({
        text: 'All',
        value: '',
        uniqueKey: `all`,
      });
      this.countryStore.response.map((data) => {
        this.countries.push({
          text: data.code,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.code}_`),
        });
      });
      this.currentCountry = this.userProfileStore.userProfile.country;
      if (
        this.currentCountry === null ||
        this.userProfileStore.userProfile.role === Role.Regional
      ) {
        this.countryIndex = 0; // For regional user, it should be ALL option as default
      } else if (this.currentCountry) {
        this.countryIndex = this.countries.findIndex((data) => {
          if (
            this.currentCountry !== null &&
            this.currentCountry.code !== null
          ) {
            return data.text === this.currentCountry.code;
          }
        });
      }
    }
  }

  mounted() {
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.userProfileStore.userProfile) {
      this.countryStore.getAllCountries();
    }
  }

  get updateText() {
    if (!this.selectedStartDate || !this.selectedEndDate) {
      // Execute if either one is empty
      return '';
    } else if (this.selectedStartDate === this.selectedEndDate) {
      return `Updates on ${this.selectedStartDate}`;
    } else {
      return `Updates from ${this.selectedStartDate} to ${this.selectedEndDate}`;
    }
  }

  get currentDate() {
    return new Date();
  }

  handleStartDateSelected(startDateData: Date) {
    this.selectedStartDate = getFirstDayOfMonth(startDateData).toLocaleString(
      'en-GB',
      {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }
    );
    this.startDate = getFirstDayOfMonth(startDateData);
    this.$emit('startDateData', getFirstDayOfMonth(startDateData));
  }

  handleEndDateSelected(endDateData: Date) {
    this.selectedEndDate = getLastDayOfMonth(endDateData).toLocaleString(
      'en-GB',
      {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }
    );
    this.endDate = getLastDayOfMonth(endDateData);
    this.$emit('endDateData', getLastDayOfMonth(endDateData));
  }

  // When selecting country from filter
  changeFilter(role: DropdownOption) {
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = 0;
    if (role && role.value) {
      this.currentFilter = role.value;
      this.$emit('countryData', role);
    } else if (role && role.value === '') {
      this.currentFilter = role.value;
      this.$emit('countryData', role);
    }
  }
}
