





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Quartiles } from '@/models/swine/report/report.model';

@Component({
  components: {},
})
export default class APPIndexPlaceholder extends Vue {
  @Prop({})
  data!: Quartiles;

  get firstQuartileValue() {
    return this.data.firstQuartile;
  }

  get secondQuartileValue() {
    return this.data.secondQuartile;
  }

  get thirdQuartileValue() {
    return this.data.thirdQuartile;
  }
}
