

































































import { Vue, Prop, Component } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class ResultSummaryLegend extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  data!: any;
}
