



















import { Vue, Prop, Component } from 'vue-property-decorator';
import DashboardCardTemplate from '@/components/swine/reports/DashboardCardTemplate.vue';

@Component({
  components: { DashboardCardTemplate },
})
export default class CounterCard extends Vue {
  @Prop({})
  image!: string;

  @Prop({})
  data!: any;

  @Prop({})
  subtitle!: string;

  @Prop({})
  emptyValue!: string;

  @Prop({})
  className!: string;
}
