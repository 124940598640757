



















import { Vue, Prop, Component } from 'vue-property-decorator';
import DashboardCardTemplate from '@/components/swine/reports/DashboardCardTemplate.vue';
import APPIndexPlaceholder from './APPIndexPlaceholder.vue';
import { Quartiles } from '@/models/swine/report/report.model';

@Component({
  components: { DashboardCardTemplate, APPIndexPlaceholder },
})
export default class APPIndexChartCard extends Vue {
  @Prop({})
  image!: string;

  @Prop({})
  title!: string;

  @Prop({})
  data!: Quartiles | null;

  @Prop({})
  noData!: string;
}
