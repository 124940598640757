








import { Vue, Component } from 'vue-property-decorator';
import TableLayout from '@/components/swine/layout/TableLayout.vue';
import { Prop } from 'vue-property-decorator';
import {
  RecentAuditCardsColumn,
  RecentAuditCardsData,
} from '@/models/swine/dashboard.model';

@Component({
  components: {
    TableLayout,
  },
})
export default class ClientTable extends Vue {
  @Prop({})
  clientList!: RecentAuditCardsData;

  @Prop({})
  columns!: RecentAuditCardsColumn;
}
