
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Page from '@/components/swine/layout/Page.vue';
import CounterCard from '@/components/swine/home/CounterCard.vue';
import ClientCard from '@/components/swine/home/ClientCard.vue';
import ResultSummaryCard from '@/components/swine/home/ResultSummaryCard.vue';
import APPIndexChartCard from '@/components/swine/home/APPIndexChartCard.vue';
import RecentAuditCard from '@/components/swine/home/RecentAuditCard.vue';
import DashboardFilter from '@/components/swine/home/DashboardFilter.vue';
import { DashboardStores } from '@/store/swine/dashboard/index';
import {
  AppIndexChartCards,
  AuditReportCard,
  ClientCards,
  DashboardDetails,
  GetDashboardParams,
  LungsExaminedCard,
  RecentAuditCards,
  ResultSummaryCards,
} from '@/models/swine/dashboard.model';
import {
  convertDateToLocalDDMMYYYYWithSlashes,
  convertDateToYYYYMMDDwithDashesAndUTC,
} from '@/utils/moment';
import { DropdownOption } from '@/models/swine/dropdown.model';

@Component({
  components: {
    Page,
    CounterCard,
    ClientCard,
    ResultSummaryCard,
    APPIndexChartCard,
    RecentAuditCard,
    DashboardFilter,
  },
})
export default class Home extends Vue {
  // Store Import
  store = DashboardStores.details;
  params: GetDashboardParams | null = null;
  dashboardDetails: DashboardDetails | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  country: number | string | null = null;
  countryCodeName = '';
  countryFullName = '';
  countryList = [
    {
      codeName: 'All',
      name: 'All',
    },
    {
      codeName: 'MY',
      name: 'Malaysia',
    },
    {
      codeName: 'PH',
      name: 'Philippines',
    },
    {
      codeName: 'TH',
      name: 'Thailand',
    },
    {
      codeName: 'VN',
      name: 'Vietnam',
    },
    {
      codeName: 'ID',
      name: 'Indonesia',
    },
    {
      codeName: 'CLM',
      name: 'CLM',
    },
    {
      codeName: 'CN',
      name: 'China',
    },
    {
      codeName: 'BN',
      name: 'Brunei'
    },
    {
      codeName: 'TW',
      name: 'Taiwan'
    }
  ];

  clientCards: ClientCards = {
    image: 'ic-dash-client.svg',
    data: null,
    subtitle: 'Total Clients',
    emptyValue: 'No new client',
    newFarms: null,
    newFarmUnits: null,
    className: 'client',
  };

  totalAuditReportCard: AuditReportCard = {
    image: 'ic-dash-report.svg',
    data: null,
    subtitle: 'Total Audit Reports',
    emptyValue: 'No report available',
    className: 'report',
  };

  totalLungsExaminedCard: LungsExaminedCard = {
    image: 'ic-lung.svg',
    data: null,
    subtitle: 'Total Lungs Examined',
    emptyValue: 'No lungs examined',
    className: 'lung',
  };

  resultSummaryCards: ResultSummaryCards = {
    image: null,
    title: null,
    data: null,
  };

  appIndexChartCards: AppIndexChartCards = {
    image: null,
    title: null,
    data: null,
    noData: null,
  };

  recentAuditCards: RecentAuditCards = {
    title: 'Recent Audit Reports',
    data: [],
    columns: [
      {
        field: 'no',
        label: 'No.',
        width: '40',
        centered: true,
      },
      {
        field: 'farmUnitCode',
        label: 'Farm Unit Code',
        centered: true,
      },
      {
        field: 'auditDate',
        label: 'Audit Date',
        centered: true,
      },
      {
        field: 'auditor',
        label: 'Auditor',
        centered: true,
      },
      {
        field: 'auditorEmail',
        label: "Auditor's Email",
        centered: true,
      },
      {
        field: 'totalLungsExamined',
        label: 'Total Lungs Examined',
        centered: true,
      },
    ],
    noData: 'No report available',
  };

  mounted() {
    this.handleUpdateDetails();
  }

  handleStartDateRetrieved(startDateData: Date) {
    this.startDate = startDateData;
    this.handleUpdateDetails();
  }

  handleEndDateRetrieved(endDateData: Date) {
    this.endDate = endDateData;
    this.handleUpdateDetails();
  }

  handleCountryRetrieved(role: DropdownOption) {
    if (role.value) {
      this.country = role.value as number;
      this.countryCodeName = role.text;
      this.countryFullName = this.countryList[
        this.countryList.findIndex((data) => {
          return data.codeName === this.countryCodeName;
        })
      ].name;
    } else if (role.value === '' && role.text === 'All') {
      this.country = role.value;
      this.countryCodeName = role.text;
      this.countryFullName = this.countryList[
        this.countryList.findIndex((data) => {
          return data.codeName === this.countryCodeName;
        })
      ].name;
    }
    this.handleUpdateDetails();
  }

  // TODO: Hook this up to the Dropdowns and make sure that the dates are converted to UTC
  handleUpdateDetails() {
    if (
      this.startDate &&
      this.endDate &&
      this.country !== null &&
      (this.country === '' || this.country.toString().length > 0)
    ) {
      this.params = {
        country: this.country !== '' ? this.country : '',
        startDate: convertDateToYYYYMMDDwithDashesAndUTC(this.startDate),
        endDate: convertDateToYYYYMMDDwithDashesAndUTC(this.endDate),
      };
      if (this.params !== null) {
        this.store.getDashboardDetails(this.params);
      }
    }
  }

  @Watch('store.response')
  updateDashboardDetails() {
    if (this.store.response) {
      this.dashboardDetails = this.store.response;
      this.handleClientCards();
      this.handleTotalAuditReportData();
      this.handletotalLungsExaminedData();
      this.handleResultSummaryCards();
      this.handleAppIndexChartCards();
      this.auditReportCardDataPopulator();
    }
  }

  handleClientCards() {
    if (this.dashboardDetails && this.clientCards) {
      this.clientCards = {
        ...this.clientCards,
        data: this.dashboardDetails.totalNumberOfClients,
        newFarms: this.dashboardDetails.newFarmsCount,
        newFarmUnits: this.dashboardDetails.newFarmUnitsCount,
      };
    }
  }

  handleTotalAuditReportData() {
    if (
      this.dashboardDetails &&
      (this.dashboardDetails.totalNumberOfAuditReports ||
        this.dashboardDetails.totalNumberOfAuditReports === 0)
    ) {
      this.totalAuditReportCard.data = this.dashboardDetails.totalNumberOfAuditReports;
    }
  }

  handletotalLungsExaminedData() {
    if (
      this.dashboardDetails &&
      (this.dashboardDetails.totalNumberOfLungsExamined ||
        this.dashboardDetails.totalNumberOfLungsExamined === 0)
    ) {
      this.totalLungsExaminedCard.data = this.dashboardDetails.totalNumberOfLungsExamined;
    }
  }

  handleResultSummaryCards() {
    if (this.dashboardDetails) {
      this.resultSummaryCards = {
        image: 'ic-dash-farmresult.svg',
        title: `${this.countryFullName} Lung Lesion Score Summary`,
        data: {
          auditor: {
            name: 'Administrator',
          },
          farmUnit: {
            id: 1,
            farmUnitCode: 'U1',
            auditReportCount: 20,
            farmId: 1,
            createdDate: '2021-03-30T03:17:33.810Z',
            updatedDate: '2021-03-30T03:20:31.818Z',
            deletedDate: null,
            farmUnitCodeName: 'MY-00-01-C1-F1-U1',
          },
          totalLungsObserved: 3,
          date: '30 Mar 2021',
          farmBatch: 'Generic String',
          weaningDate: '02 Feb 2021',
          marketDate: '04 Feb 2021',
          averageInitialWT: 50,
          averageMarketWT: 60,
          totalFeedIntake: 10,
          fcr: 3,
          pneumoniaIndex: 6,
          pneumoniaPrevalence: 1,
          appIndex: {
            appIndex: '1.667',
            totalReports: 20,
            ranking: 2,
            country: {
              id: 1,
              code: 'MY',
              name: 'Malaysia',
              createdDate: '2021-03-30T01:48:59.442Z',
              updatedDate: '2021-03-30T01:48:59.442Z',
              deletedDate: null,
            },
          },
          bronchopneumoniaLungsPercentage:
            this.dashboardDetails.bronchopneumoniaLungsPercentage === null
              ? '-'
              : this.dashboardDetails.bronchopneumoniaLungsPercentage,
          averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage:
            this.dashboardDetails
              .averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage ===
            null
              ? '-'
              : this.dashboardDetails
                  .averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage,
          pigsWithCranialPleurisyPercentage:
            this.dashboardDetails.pigsWithCranialPleurisyPercentage === null
              ? '-'
              : this.dashboardDetails.pigsWithCranialPleurisyPercentage,
          totalPigsWithDorsocaudalPleurisy:
            this.dashboardDetails.pigsWithDorsocaudalPleurisyPercentage === null
              ? '-'
              : this.dashboardDetails.pigsWithDorsocaudalPleurisyPercentage,
          totalPigswithAbscess:
            this.dashboardDetails.totalPigsWithAbscess === null
              ? '-'
              : this.dashboardDetails.totalPigsWithAbscess,
          totalPigsWithScarring:
            this.dashboardDetails.totalPigsWithScarring === null
              ? '-'
              : this.dashboardDetails.totalPigsWithScarring,
          totalPigsWithPericarditis:
            this.dashboardDetails.totalPigsWithPericarditis === null
              ? '-'
              : this.dashboardDetails.totalPigsWithPericarditis,
          normalScore: 0,
          pneumoniaScore: 0,
          severePneumoniaScore: 2,
          p1PleuritisScore: 4,
          otherPleuritisScore: 2,
          abscessCount: 3,
          scarringCount: 3,
          pericarditisCount: 3,
        },
      };
    }
  }

  get appIndexes() {
    if (this.dashboardDetails) {
      return this.dashboardDetails.countryIndustryAppIndex;
    }
    return null;
  }

  handleAppIndexChartCards() {
    this.appIndexChartCards = {
      image: 'ic-dash-appi.svg',
      title: `${this.countryFullName} Industry Dorsal Pleurisy Index`,
      data: this.appIndexes,
      noData: 'No data available',
    };
  }

  auditReportCardDataPopulator() {
    if (this.dashboardDetails) {
      let counter = 0;
      this.recentAuditCards.data = this.dashboardDetails.recentAuditReports.map(
        (report: any) => {
          counter++;
          return {
            no: `${counter}.`,
            farmUnitCode: report.reportValues.farmUnit.farmUnitCodeName,
            auditDate: convertDateToLocalDDMMYYYYWithSlashes(
              report.publishedDate,
            ),
            auditor: report.user.userProfile.name,
            auditorEmail: report.user.email,
            totalLungsExamined: report.reportValues.totalNumberOfLungs.length,
          };
        },
      );
    }
  }
}
