































import { Vue, Prop, Component } from 'vue-property-decorator';
import DashboardCardTemplate from '@/components/swine/reports/DashboardCardTemplate.vue';

@Component({
  components: { DashboardCardTemplate },
})
export default class ClientCard extends Vue {
  @Prop({})
  image!: string;

  @Prop({})
  clientNumber!: number;

  @Prop({})
  subtitle!: string;

  @Prop({})
  emptyValue!: string;

  @Prop({})
  newFarms!: number;

  @Prop({})
  newFarmUnits!: number;

  @Prop({})
  className!: string;
}
