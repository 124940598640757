


























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { compareDates, getLastDayOfMonth } from '@/utils/moment';
@Component({})
export default class DateDropdown extends Vue {
  displayDate = `Mmm yyyy`;
  selectedDate: Date | null = null;

  @Prop({
    type: Date,
    default: null,
  })
  minimumDate!: Date;

  @Prop({
    type: Date,
    default: null,
  })
  maximumDate!: Date;

  @Prop({
    type: Date,
    default: null,
  })
  currentDate!: Date;

  @Prop({
    type: String,
    default: null,
  })
  dropdownPosition!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  resetFilter!: boolean;

  @Watch('minimumDate')
  checkDates() {
    // This is only specific for end date dropdown
    // If startDate is greater than endDate
    if (
      this.minimumDate &&
      this.selectedDate &&
      compareDates(this.minimumDate, getLastDayOfMonth(this.selectedDate)) < 0
    ) {
      this.updateSelectedDate(null);
    }
  }

  @Emit(`select`)
  updateSelectedDate(selectedDate: Date | null) {
    this.selectedDate = selectedDate;
    return selectedDate;
  }

  @Emit('reset')
  resetComplete() {
    if (this.resetFilter) {
      return false;
    }
  }

  mounted() {
    this.setDefaultDate();
  }

  setDefaultDate() {
    if (this.currentDate) {
      this.selectedDate = this.currentDate;
      this.updateSelectedDate(this.currentDate);
    }
  }

  @Watch('resetFilter')
  resetSelections() {
    if (this.resetFilter) {
      this.selectedDate = null;
      this.updateSelectedDate(null);
      this.resetComplete();
    }
  }
}
