
















import { Vue, Prop, Component } from 'vue-property-decorator';
import DashboardCardTemplate from '@/components/swine/reports/DashboardCardTemplate.vue';
import RecentAuditTable from './RecentAuditTable.vue';
import {
  RecentAuditCardsColumn,
  RecentAuditCardsData,
} from '@/models/swine/dashboard.model';

@Component({
  components: {
    DashboardCardTemplate,
    RecentAuditTable,
  },
})
export default class RecentAuditCard extends Vue {
  @Prop({
    type: String,
    default: `Recent Audit Reports`,
  })
  title!: string;

  @Prop({})
  data!: RecentAuditCardsData;

  @Prop({})
  columns!: RecentAuditCardsColumn;

  @Prop({})
  noData!: string;
}
