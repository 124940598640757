












































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import DashboardCardTemplate from '@/components/swine/reports/DashboardCardTemplate.vue';
import PercentageTable from '@/components/swine/reports/PercentageTable.vue';
import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import ResultSummaryLegend from './ResultSummaryLegend.vue';

@Component({
  components: {
    DashboardCardTemplate,
    PercentageTable,
    BasicBarChart,
    ResultSummaryLegend,
  },
})
export default class ResultSummaryCard extends Vue {
  @Prop({})
  image!: string;

  @Prop({})
  title!: string;

  @Prop({
    type: Object,
    default: null,
  })
  data!: any;

  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;

  @Watch('data', { deep: true, immediate: true })
  barChartPopulator() {
    if (this.data) {
      this.options = {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 20,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 120,
                callback: function (tick) {
                  return tick.toString() + '%';
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function (value) {
              return value ? `${value} %` : '-%';
            },
          },
        },
      };

      this.chartData = {
        labels: [`B.L.`, `A.P.`, `C.P.`, `D.P.`, `Absc.`, `Scar.`, `Peri.`],
        datasets: [
          {
            data: [
              this.data.bronchopneumoniaLungsPercentage,
              this.data
                .averageOfAffectedSurfaceOutOfLungsWithActivePneumoniaPercentage,
              this.data.pigsWithCranialPleurisyPercentage,
              this.data.totalPigsWithDorsocaudalPleurisy,
              this.data.totalPigswithAbscess,
              this.data.totalPigsWithScarring,
              this.data.totalPigsWithPericarditis,
            ],
            backgroundColor: [
              `#D6FFA4`,
              `#99E895`,
              `#FFF5C9`,
              `#FFD16A`,
              `#FFEAEA`,
              `#FFC1C1`,
              `#D9F2FF`,
            ],
            borderColor: [
              `#6C9D31`,
              `#6C9D31`,
              `#B99913`,
              `#B99913`,
              `#F15453`,
              `#F15453`,
              `#00A5E1`,
            ],
            borderWidth: 0.5,
            barThickness: 21,
          },
        ],
      };
    }
  }
}
